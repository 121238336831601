<template>
    <div>
        <h1>Dossier Document Requirements</h1>
        <p>To see an explanation of each type of document go to
            <router-link to="/documentTypes">Document Types </router-link>
            <i class="md-icon md-icon-font inline-header-icon md-theme-default">open_in_new</i>
        </p>
        <div id="requirement-grid">
            <table v-if="documentTypes">
                <thead>
                    <tr>
                        <th class="fixed" style="z-index: 5">Action/Title</th>
                        <th class="doc-type" v-for="(docType, docTypeKey) in documentTypes" v-bind:key="docTypeKey">
                            <span v-if="docType">{{docType.nameShort}}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in titleActions" v-bind:key="index">
                        <td class="fixed" style="z-index: 4">{{item.actionType}} - {{item.title}}<br>{{item.specialCase}}</td>
                        <td class="doc-type" v-for="(docType, docTypeCode) in documentTypes" v-bind:key="docTypeCode">
                            <md-icon v-if="isRequired(index, docTypeCode)">check</md-icon>
                            {{getNumRequired(index,docTypeCode)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RequirementGrid',
  data: () => ({
  }),
  computed: {
    titleActions() {
      return this.$store.getters['title/titleActions'];
    },
    documentTypes() {
      return this.$store.getters['title/documentTypes'];
    }
  },
  methods: {
    isRequired(titleActionIndex, docTypeCode) {
      let req = this.getRequirement(titleActionIndex, docTypeCode);
      if(req === null) return false;
      if(req.minAllowed > 0) return true;
      return false;
    },
    getNumRequired(titleActionIndex, docTypeCode) {
      let req = this.getRequirement(titleActionIndex, docTypeCode);
      if(req === null) return '';
      if(req.minAllowed !== req.maxAllowed) return '(' + req.minAllowed.toString() + '-' + req.maxAllowed.toString() + ')'
      if(req.minAllowed === req.maxAllowed && req.minAllowed > 1) return '(' + req.minAllowed.toString() + ')';
      return '';
    },
    getRequirement(titleActionIndex, docTypeCode) {
      if(docTypeCode === 'SIGNED_OFFER_LET') {
        if(this.titleActions[titleActionIndex].title.substr(0,9) !== 'Secondary') return {minAllowed: 1, maxAllowed: 1};
      }
      for(let req of this.titleActions[titleActionIndex].documents) {
        if(req.documentTypeCode === docTypeCode) {
          return req;
        }
      }
      return null;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #requirement-grid {
        display: flex;
        flex-flow: row;
        justify-content: center;
    }
    th {
      position: sticky;
      top: 0;
      background-color: #cccccc;
    }
    td,th {
        border: 1px solid black;
        padding: 0 3px 0 3px;
    }
    td.fixed, th.fixed {
      position: sticky;
      left: 0;
      background-color: #cccccc;
    }
    tbody tr:hover {
        background-color: #EEEEFF;
    }

    td.doc-type, th.doc-type {
        width: 75px;
    }
    table {
        border-collapse: collapse
    }

</style>
